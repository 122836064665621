<template>
  <div>
    <div style="background:#1018af">
      <el-image width="1920" height="568" :src="bannerUrl"></el-image>
    </div>

    <div style="width:100%; height:68px; background:#475AFF">
      <div style="width:1320px; margin:0 auto; text-align:left">
        <ul>
          <router-link :to="{ path: '/components/web/solutions/case01' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                class="tabStyle2"
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>01</div>
                <div>{{ $t('Nav.qiyezonghehuaguanli') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/solutions/case02' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>02</div>
                <div>{{ $t('Nav.nengyuanshihuaguanli') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/solutions/case03' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>03</div>
                <div>{{ $t('Nav.guojiyunying') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/solutions/case04' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>04</div>
                <div>{{ $t('Nav.dashuju') }}</div>
              </div>
            </li>
          </router-link>
          <!-- <router-link :to="{ path: '/components/web/solutions/case05' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>05</div>
                <div>{{ $t('Nav.xitongjishuyuzhichi') }}</div>
              </div>
            </li>
          </router-link> -->
        </ul>
      </div>
    </div>
    <div
      style="width:1200px; margin:0 auto; height: 44px;box-shadow: 0px 1px 0px 0px rgba(71, 90, 255, 0.1);"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span style="color:#303133">{{
            $t('Nav.home')
          }}</span></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><span style="color:#303133">{{
            $t('Nav.cpfa')
          }}</span></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div style="height:30px"></div>
    <div style="width:1200px; margin:0 auto;">
      <el-row :gutter="20">
        <router-link :to="{ path: '/components/web/solutions/case04-1' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(1)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case01"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>
              <transition name="el-zoom-in-center">
                <div
                  v-show="showText"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.sdhujuzhongxin') }}</div>
                    <div class="imgText">{{ $t('Nav.sdhujuzhongxin01') }}</div>
                    <div class="imgText">{{ $t('Nav.sdhujuzhongxin02') }}</div>
                    <div class="imgText">{{ $t('Nav.sdhujuzhongxin03') }}</div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case04-2' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(2)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case02"
                fit="contain"
                style="width:100%; height:300px;"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText2"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case02_1') }}</div>
                    <div class="imgText">
                      {{ $t('Nav.case02_14') }}
                    </div>
                    <div class="imgText">{{ $t('Nav.case02_15') }}</div>
                    <div class="imgText">{{ $t('Nav.case02_12') }}</div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case02-1' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(3)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case03"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText3"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case03_1') }}</div>
                    <div class="imgText">{{ $t('Nav.case03_2') }}</div>
                    <div class="imgText">{{ $t('Nav.case03_3') }}</div>
                    <div class="imgText">{{ $t('Nav.case03_4') }}</div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
      </el-row>

      <el-row :gutter="20" style="margin:20px 0;">
        <router-link :to="{ path: '/components/web/solutions/case02-2' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(4)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case04"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText4"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case04_1') }}</div>
                    <div class="imgText">{{ $t('Nav.case04_16') }}</div>
                    <div class="imgText">{{ $t('Nav.case04_17') }}</div>
                    <div class="imgText">{{ $t('Nav.case04_18') }}</div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case01-5' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(5)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case05"
                fit="contain"
                style="width:100%; height:300px;"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText5"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case05_1') }}</div>
                    <div class="imgText">{{ $t('Nav.case05_2') }}</div>
                    <div class="imgText">{{ $t('Nav.case05_3') }}</div>
                    <div class="imgText">{{ $t('Nav.case05_4') }}</div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case01-1' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(6)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case06"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText6"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case06_1') }}</div>
                    <div class="imgText">{{ $t('Nav.case06_4') }}</div>
                    <div class="imgText">{{ $t('Nav.case06_6') }}</div>
                    <div class="imgText">{{ $t('Nav.case06_9') }}</div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
      </el-row>
      <el-row :gutter="20" style="margin:20px 0">
        <router-link :to="{ path: '/components/web/solutions/case03-1' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(7)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case07"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText7"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case07_1') }}</div>
                    <div class="imgText">{{ $t('Nav.case07_2') }}</div>
                    <div class="imgText">{{ $t('Nav.case07_3') }}</div>
                    <div class="imgText">{{ $t('Nav.case07_4') }}</div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case01-2' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(8)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case08"
                fit="contain"
                style="width:100%; height:300px;"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText8"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">
                      {{ $t('Nav.case08_1') }}
                    </div>
                    <div class="imgText">
                      {{ $t('Nav.case08_2') }}
                    </div>
                    <div class="imgText">{{ $t('Nav.case08_3') }}</div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case01-6' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(9)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case09"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText9"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case09_1') }}</div>
                    <div class="imgText">{{ $t('Nav.case09_2') }}</div>
                    <div class="imgText">{{ $t('Nav.case09_3') }}</div>
                    <div class="imgText">
                      {{ $t('Nav.case09_4') }}
                    </div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case01-3' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(10)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case10"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText10"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case10_1') }}</div>
                    <div class="imgText">{{ $t('Nav.case10_2') }}</div>
                    <div class="imgText">
                      {{ $t('Nav.case10_3') }}
                    </div>
                    <div class="imgText">
                      {{ $t('Nav.case10_4') }}
                    </div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case01-4' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(11)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case11"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText11"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case11_1') }}</div>
                    <div class="imgText">{{ $t('Nav.case11_2') }}</div>
                    <div class="imgText">
                      {{ $t('Nav.case11_3') }}
                    </div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case02-3' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(12)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case12"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText12"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case12_1') }}</div>
                    <div class="imgText">{{ $t('Nav.case12_2') }}</div>
                    <div class="imgText">
                      {{ $t('Nav.case12_3') }}
                    </div>
                    <div class="imgText">
                      {{ $t('Nav.case12_4') }}
                    </div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case02-4' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(13)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case13"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText13"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case13_1') }}</div>
                    <div class="imgText">{{ $t('Nav.case13_2') }}</div>
                    <div class="imgText">
                      {{ $t('Nav.case13_3') }}
                    </div>
                    <div class="imgText">
                      {{ $t('Nav.case13_4') }}
                    </div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case02-5' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(14)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case14"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText14"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case14_1') }}</div>
                    <div class="imgText">{{ $t('Nav.case14_2') }}</div>
                    <div class="imgText">
                      {{ $t('Nav.case14_3') }}
                    </div>
                    <div class="imgText">
                      {{ $t('Nav.case14_4') }}
                    </div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case03-2' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(15)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case15"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText15"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case15_1') }}</div>
                    <div class="imgText">{{ $t('Nav.case15_2') }}</div>
                    <div class="imgText">
                      {{ $t('Nav.case15_3') }}
                    </div>
                    <div class="imgText">
                      {{ $t('Nav.case15_4') }}
                    </div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case03-3' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(16)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case16"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText16"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case16_1') }}</div>
                    <div class="imgText">{{ $t('Nav.case16_2') }}</div>
                    <div class="imgText">
                      {{ $t('Nav.case16_3') }}
                    </div>
                    <div class="imgText">
                      {{ $t('Nav.case16_4') }}
                    </div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case04-3' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(17)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case17"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText17"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case17_1') }}</div>
                    <div class="imgText">{{ $t('Nav.case17_2') }}</div>
                    <div class="imgText">
                      {{ $t('Nav.case17_3') }}
                    </div>
                    <div class="imgText">
                      {{ $t('Nav.case17_4') }}
                    </div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/solutions/case02-6' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(18)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case18"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>

              <transition name="el-zoom-in-center">
                <div
                  v-show="showText18"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:50px"
                    ></div>
                    <div class="imgText">{{ $t('Nav.case18_1') }}</div>
                    <div class="imgText">
                      {{ $t('Nav.case18_3') }}
                    </div>
                    <div class="imgText">
                      {{ $t('Nav.case18_4') }}
                    </div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
      </el-row>
      <!-- <el-pagination
        style="margin-top:30px;margin-bottom:30px"
        background
        layout="prev, pager, next"
        :total="1000"
      >
      </el-pagination> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'solutions',
  components: {},
  data() {
    return {
      bannerUrl: require('../../../assets/33.jpg'),
      corporateVision1: require('../../../assets/aboutus/corporateVision-img1.png'),
      corporateVision2: require('../../../assets/aboutus/corporateVision-img2.png'),
      corporateVision3: require('../../../assets/aboutus/corporateVision-img3.png'),
      img1: require('../../../assets/index/img1.png'),
      img2: require('../../../assets/index/img2.png'),
      img3: require('../../../assets/index/img3.png'),
      case01: require('../../../assets/chanpin/case01.jpg'),
      case02: require('../../../assets/chanpin/case02.jpg'),
      case03: require('../../../assets/chanpin/case03.jpg'),
      case04: require('../../../assets/chanpin/case04.png'),
      case05: require('../../../assets/chanpin/case05.png'),
      case06: require('../../../assets/chanpin/case06.png'),
      case07: require('../../../assets/chanpin/case07.png'),
      case08: require('../../../assets/chanpin/case08.png'),
      case09: require('../../../assets/chanpin/case09.png'),
      case10: require('../../../assets/chanpin/case10.jpg'),
      case11: require('../../../assets/chanpin/case11.png'),
      case12: require('../../../assets/chanpin/case12.png'),
      case13: require('../../../assets/chanpin/case13.jpg'),
      case14: require('../../../assets/chanpin/case14.jpg'),
      case15: require('../../../assets/chanpin/case15.jpg'),
      case16: require('../../../assets/chanpin/case16.jpg'),
      case17: require('../../../assets/chanpin/case17.jpg'),
      case18: require('../../../assets/chanpin/case18.jpg'),
      showText: false,
      showText2: false,
      showText3: false,
      showText4: false,
      showText5: false,
      showText6: false,
      showText7: false,
      showText8: false,
      showText9: false,
      showText10: false,
      showText11: false,
      showText12: false,
      showText13: false,
      showText14: false,
      showText15: false,
      showText16: false,
      showText17: false,
      showText18: false,
    }
  },
  mounted() {
    this.$bus.$emit('getCurNav', 'solutions')
  },
  methods: {
    showTextFun(num) {
      if (num == 1) {
        this.showText = true
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
        this.showText13 = false
        this.showText14 = false
        this.showText15 = false
        this.showText16 = false
        this.showText17 = false
        this.showText18 = false
      } else if (num == 2) {
        this.showText = false
        this.showText2 = true
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
        this.showText13 = false
        this.showText14 = false
        this.showText15 = false
        this.showText16 = false
        this.showText17 = false
        this.showText18 = false
      } else if (num == 3) {
        this.showText = false
        this.showText2 = false
        this.showText3 = true
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
        this.showText13 = false
        this.showText14 = false
        this.showText15 = false
        this.showText16 = false
        this.showText17 = false
        this.showText18 = false
      } else if (num == 4) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = true
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
        this.showText13 = false
        this.showText14 = false
        this.showText15 = false
        this.showText16 = false
        this.showText17 = false
        this.showText18 = false
      } else if (num == 5) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = true
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
        this.showText13 = false
        this.showText14 = false
        this.showText15 = false
        this.showText16 = false
        this.showText17 = false
        this.showText18 = false
      } else if (num == 6) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = true
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
        this.showText13 = false
        this.showText14 = false
        this.showText15 = false
        this.showText16 = false
        this.showText17 = false
        this.showText18 = false
      } else if (num == 7) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = true
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
        this.showText13 = false
        this.showText14 = false
        this.showText15 = false
        this.showText16 = false
        this.showText17 = false
        this.showText18 = false
      } else if (num == 8) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = true
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
        this.showText13 = false
        this.showText14 = false
        this.showText15 = false
        this.showText16 = false
        this.showText17 = false
        this.showText18 = false
      } else if (num == 9) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = true
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
        this.showText13 = false
        this.showText14 = false
        this.showText15 = false
        this.showText16 = false
        this.showText17 = false
        this.showText18 = false
      } else if (num == 10) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = true
        this.showText11 = false
        this.showText12 = false
        this.showText13 = false
        this.showText14 = false
        this.showText15 = false
        this.showText16 = false
        this.showText17 = false
        this.showText18 = false
      } else if (num == 11) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = true
        this.showText12 = false
        this.showText13 = false
        this.showText14 = false
        this.showText15 = false
        this.showText16 = false
        this.showText17 = false
        this.showText18 = false
      } else if (num == 12) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = true
        this.showText13 = false
        this.showText14 = false
        this.showText15 = false
        this.showText16 = false
        this.showText17 = false
        this.showText18 = false
      } else if (num == 13) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
        this.showText13 = true
        this.showText14 = false
        this.showText15 = false
        this.showText16 = false
        this.showText17 = false
        this.showText18 = false
      } else if (num == 14) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
        this.showText13 = false
        this.showText14 = true
        this.showText15 = false
        this.showText16 = false
        this.showText17 = false
        this.showText18 = false
      } else if (num == 15) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
        this.showText13 = false
        this.showText14 = false
        this.showText15 = true
        this.showText16 = false
        this.showText17 = false
        this.showText18 = false
      } else if (num == 16) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
        this.showText13 = false
        this.showText14 = false
        this.showText15 = false
        this.showText16 = true
        this.showText17 = false
        this.showText18 = false
      } else if (num == 17) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
        this.showText13 = false
        this.showText14 = false
        this.showText15 = false
        this.showText16 = false
        this.showText17 = true
        this.showText18 = false
      } else if (num == 18) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
        this.showText8 = false
        this.showText9 = false
        this.showText10 = false
        this.showText11 = false
        this.showText12 = false
        this.showText13 = false
        this.showText14 = false
        this.showText15 = false
        this.showText16 = false
        this.showText17 = false
        this.showText18 = true
      }
    },
    hideTextFun() {
      this.showText = false
      this.showText2 = false
      this.showText3 = false
      this.showText4 = false
      this.showText5 = false
      this.showText6 = false
      this.showText7 = false
      this.showText8 = false
      this.showText9 = false
      this.showText10 = false
      this.showText11 = false
      this.showText12 = false
      this.showText13 = false
      this.showText14 = false
      this.showText15 = false
      this.showText16 = false
      this.showText17 = false
      this.showText18 = false
    },
  },
}
</script>

<style>
ul {
  margin: 0;
}
.tabStyle {
  color: #ffffff;
  display: inline-block;
  height: 68px;
  width: 220px;
}
.tabStyleLi {
  display: inline-block;
  height: 68px;
  position: relative;
}
.tabStyle:hover {
  background: #3246f4 !important;
  opacity: 0.7;
  height: 68px;
  cursor: pointer;
}

.el-image {
  overflow: visible !important;
}
.el-breadcrumb {
  line-height: 44px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.jjfaTitle {
  height: 20px;
  font-size: 21px;
  font-family: Noto Sans S Chinese;
  font-weight: 300;
  color: #2c3dd6;
  line-height: 37px;
  margin: 5px 0;
}
.jjfamiaoshu {
  height: 16px;
  font-size: 16px;
  font-family: Noto Sans S Chinese;
  font-weight: 300;
  color: #666666;
  line-height: 37px;
}
.grid-content {
  text-align: center;
  height: 250px;
  line-height: 50px;
  padding-top: 50px;
}
.grid-content:hover {
  cursor: pointer;
  background: #f6f8f9;
  height: 250px;
}
.grid-content2 {
  position: relative;
  cursor: pointer;
}
.imgText {
  font-size: 14px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #ffffff;
  margin: 10px 0 5px 0;
}
.hzgs {
  height: 23px;
  font-size: 24px;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #475aff;
  line-height: 37px;
}
.el-row {
  margin: 0px !important;
}
</style>
